@import "../../styles/variables.scss";
$labelHeight: 25px;

@mixin label {
    position: absolute;
    width: 100%;
    content: "publicité";
    font-size: 11px;
    line-height: 11px;
    font-family: arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: $labelHeight;
    text-transform: uppercase;
    text-align: center;
    color: $couleur-text-dark;
}

@mixin labelTop {
    position: relative;

    &:before {
        @include label;
        background: $couleur-neutre-light;
        top: 0;
    }
}

.pub {
    position: relative;
    display: block;
    min-width: 300px;

    [data-m32-dynamic-ad] {
        margin: 0 auto;
        padding-top: $labelHeight;
        max-width: max-content;
        min-height: var(--pub-height);
        height: calc(100% + #{$labelHeight});
        overflow: hidden;

        @media #{$mq-medium-and-over} {
            min-height: var(--pub-height-desktop, var(--pub-height));
        }

        &:not([style="display: none;"]) {
            @include labelTop;
        }

        div {
            margin: 0 auto;
            width: max-content;
        }
    }

    &.leaderboard-billboard:has([data-m32-dynamic-ad]:not([style="display: none;"])),
    &.leaderboard-billboard-big:has([data-m32-dynamic-ad]:not([style="display: none;"])),
    &.leaderboard-billboard-double:has([data-m32-dynamic-ad]:not([style="display: none;"])) {
        padding: .75rem 0 2rem;
    }

    @media print {
        display: none;
    }
}